import React, { useContext, useState } from "react";
import { Button, Dialog, Typography, DialogContent, DialogContentText, DialogTitle, DialogActions, useMediaQuery, withStyles } from "@material-ui/core";
import { ReactComponent as AccountSvg } from '../../../assets/sidemenu/login.svg'
import AdminBox from '../AdminBox';
import { FormProvider, useForm } from "react-hook-form";
import { BetterControlledTextField } from "../../Core/Form/ControlledFields";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from 'yup';
import { OrangeButton } from "../../Core/Buttons/OrangeButton";
import { BlueButton } from "../../Core/Buttons/BlueButton";
import { SessionContextStore } from '../../../context/SessionContext/SessionContext';
import FindExistingUserPopup from './FindExistingUserPopup'
const CustomDialog = withStyles(theme => ({
    paper: {
        width: '100%',
        padding: '10px'
    }
}))(Dialog);

export const QuoteUserSchema = yup
.object()
  .shape({
    email: yup.string().required("Required").trim().email("Must be a valid email address")
})
.required();

const QuoteUserBox = () => {
    const [openDialog, setOpenDialog] = useState(false);
    const [searchExistingUserPopup, setSearchExistingUserPopup] = useState(false)
    const { impersonateUser } = useContext(SessionContextStore);
    const isMobile = useMediaQuery(theme => theme.breakpoints.down('sm'));
    const form = useForm({mode: 'onSubmit', resolver: yupResolver(QuoteUserSchema)});

    const handleClose = () => {
        setOpenDialog(false);
    }

    const handleOpen = () => {
        setOpenDialog(true);
    }

    const handleOpenSearchExistingUser = () =>{
        setSearchExistingUserPopup(true)
    }

    const handleCloseSearchExistingUser = () =>{
        setSearchExistingUserPopup(false)
    }

    const onSubmit = async (data) => {
        await impersonateUser(data);
    }

    return <AdminBox Icon={AccountSvg} title="Quote new user / existing account">
        <Button className="no-text-transform" variant="text" onClick={handleOpen}>
            <Typography variant="body2">New user</Typography>
        </Button>
        <br/>
        <Button className="no-text-transform" variant="text" onClick={handleOpenSearchExistingUser}>
            <Typography variant="body2">Load user</Typography>
        </Button>
        <CustomDialog maxWidth={'sm'} open={openDialog} onClose={handleClose}>
            <form className="margin-top-8" onSubmit={form.handleSubmit(onSubmit)}>
                <DialogTitle style={{ cursor: 'move' }} id="draggable-dialog-title">
                  <Typography variant="h3" className="blue bold">Quote / Bind Coverage</Typography>
                </DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        <Typography variant="body2" className="margin-bottom-6">Insert the insured's email</Typography>
                        <FormProvider {...form}>
                            <BetterControlledTextField fullWidth name="email" placeholder="Email"/>
                        </FormProvider>
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <OrangeButton type="submit">
                        Quote user
                    </OrangeButton>
                    <BlueButton onClick={handleClose}>
                        Cancel
                    </BlueButton>
                </DialogActions>
            </form>
        </CustomDialog>
        <FindExistingUserPopup onCancel={handleCloseSearchExistingUser} open={searchExistingUserPopup} onSubmit={onSubmit}/>
    </AdminBox>
}

export default QuoteUserBox;