import React, { useReducer, createContext, useContext } from 'react';

const CANCELLATION_ACTIONS = {
    START_CANCELLATION: 'START_CANCELLATION',
    FINISH_CANCELLATION: 'FINISH_CANCELLATION'
};

const defaultState = {
    policy: null,
    isOpen: false
};

const reducer = (state, action) => {
    const payload = action.payload;
    switch (action.type) {
        case CANCELLATION_ACTIONS.START_CANCELLATION:
            return {
                ...state,
                policy: payload.policy,
                policyStatus: payload.policyStatus,
                isOpen: true
            };
        case CANCELLATION_ACTIONS.FINISH_CANCELLATION:
            return {
                ...state,
                policy: null,
                isOpen: false
            };
        default:
            return state;
    }
};

export const CancellationContextStore = createContext(defaultState);

const CancellationContext = ({ children }) => {
    const [state, dispatch] = useReducer(reducer, defaultState);

    const startCancellation = (policy, policyStatus) => {
        dispatch({
            type: CANCELLATION_ACTIONS.START_CANCELLATION,
            payload: { policy, policyStatus }
        });
    };

    const finishCancellation = () => {
        dispatch({
            type: CANCELLATION_ACTIONS.FINISH_CANCELLATION
        });
    };

    return (
        <CancellationContextStore.Provider
            value={{ ...state, startCancellation, finishCancellation }}
        >
            {children}
        </CancellationContextStore.Provider>
    );
};

export const useCancellation = () => useContext(CancellationContextStore);

export default CancellationContext;
