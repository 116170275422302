import { Container, Grid, Typography, useMediaQuery } from '@material-ui/core';
import React, { useContext, useEffect } from 'react';
import { useCancellation } from '../../../context/CancellationContext/CancellationContext';
import { SkywatchDialog } from '../../PopUps/SkywatchDialog';
import { FormProvider, useForm } from 'react-hook-form';
import { ControlledTextField } from '../../Core/Form/ControlledFields';
import { ControlledCheckBox } from '../../Core/Form/CheckBox';
import { ControlledDatePicker } from '../../Core/Form/Datepickers';
import cancellationFormSchema from '../schemas/CancellationFormSchema';
import { yupResolver } from '@hookform/resolvers/yup';
import insuranceApi from '../../../api/insurance.api';
import dayjs from 'dayjs';
import { LoadingContextStore } from '../../../context/LoadingContext/LoadingContext';
import { AlertContextStore } from '../../../context/AlertContext/AlertContext';
import { OrangeButtonPolicyManagment } from '../../Core/Buttons/OrangeButtonPolicyManagment';
import { POLICY_STATUS } from '../../../context/PolicyContext/types';

const CancellationModal = ({fetchPolicies}) => {
    const { policy, policyStatus,isOpen, finishCancellation } = useCancellation();
    const isMobile = useMediaQuery((theme) => theme.breakpoints.down('sm'));
    const { awaitWithLoading } = useContext(LoadingContextStore);
    const {showError, showInfo} = useContext(AlertContextStore);
    const form = useForm({
        mode: 'onSubmit',
        defaultValues: {
            amount: '',
            is_full_refund: false,
            cancellation_date: null
        },
        resolver: yupResolver(cancellationFormSchema)
    });

    useEffect(() => {
        if (!policy) return;
        form.reset({
            amount: policyStatus === POLICY_STATUS.ACTIVE? '' : 0,
            is_full_refund: policyStatus === POLICY_STATUS.ACTIVE? false : true,
            cancellation_date: policyStatus === POLICY_STATUS.ACTIVE? null : new Date(),
            start_date: policy ? new Date(policy.start_date) : null,
            end_date: policy ? new Date(policy.end_date) : null
        });
    }, [policy]);

    useEffect(() => {
        form.register('start_date');
        form.register('end_date');
        form.register('amount');
        form.register('cancellation_date');
        form.register('is_full_refund');

        return () => {
            form.unregister('start_date')
            form.unregister('end_date')
            form.unregister('amount');
            form.unregister('cancellation_date');
            form.unregister('is_full_refund');

        }

    },[form.register, form.unregister])

    const isFullRefund = form.watch('is_full_refund');

    const onSubmit = async (data) => {
        const now = new Date()
        data.cancellation_date.setMinutes(now.getMinutes())
        data.cancellation_date.setHours(now.getHours())
        data.cancellation_date = dayjs(data.cancellation_date).format()

        const cancel = async () => {
            const res = await insuranceApi.cancelInsurance({insurance_id: policy.policy_id, ...data});
            if( res.status === 400 ){
                showError({title: res.data.title});
            } else if (res.ok) {
                showInfo({title: "Cancelled successfully"});
            } else {
                showError({title: "An internal server error occurred"});
            }

            await finishCancellation();
        }

        await awaitWithLoading(cancel())
    };

    return (
        <SkywatchDialog
            fullScreen={isMobile}
            open={isOpen}
            closeDialog={finishCancellation}
            maxWidth={'xs'}
        >
            <div className="margin-top-basic margin-bottom-basic">
                <Typography variant="h2" className="margin-bottom-basic" color="primary" style={{ textAlign: 'center' }}>
                    Cancellation form
                </Typography>
                <Container>
                    <form onSubmit={form.handleSubmit(onSubmit)}>
                        <FormProvider {...form}>
                        {policyStatus == POLICY_STATUS.ACTIVE && 
                            <Grid container>
                                <Grid
                                    item
                                    xs={12}
                                    className="margin-bottom-small"
                                >
                                    <Typography variant="h4" className="grey">
                                        Cancellation date
                                    </Typography>
                                    <ControlledDatePicker
                                        disableFuture
                                        name="cancellation_date"
                                        className="margin-top-8"
                                        fullWidth
                                    />
                                </Grid>
                                <Grid
                                    item
                                    xs={12}
                                    className="margin-bottom-small"
                                >
                                    <Typography variant="h4" className="grey">
                                        Amount
                                    </Typography>
                                    <ControlledTextField
                                        disabled={isFullRefund}
                                        name="amount"
                                        type="number"
                                        fullWidth={true}
                                        className="margin-top-8"
                                        placeholder="Amount"
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <ControlledCheckBox
                                        name="is_full_refund"
                                        label="Full refund"
                                    />
                                </Grid>
                            </Grid>
                        }
                        {policyStatus == POLICY_STATUS.SCHEDULED && 
                            <>
                                <Typography variant="h4" className="grey" style={{ marginBottom: '16px', textAlign: 'center'}} >
                                    You are about to cancel this scheduled policy. The user will receive a full refund.
                                </Typography>
                                <Typography variant="h4" className="grey" style={{ marginBottom: '16px', textAlign: 'center'}} >
                                    Are you sure you want to proceed?
                                </Typography>
                            </>
                        }
                        </FormProvider>
                        <Grid container justifyContent="center">
                            <OrangeButtonPolicyManagment type="submit" fullWidth>Submit</OrangeButtonPolicyManagment>
                        </Grid>
                    </form>
                </Container>
            </div>
        </SkywatchDialog>
    );
};

export default CancellationModal;
