import React, { useContext, useEffect, useMemo, useState } from 'react';
import { Grid, Typography, useMediaQuery,Divider } from '@material-ui/core';
import SkywatchCard from '../../Core/Cards/SkywatchCard';
// import { ReactComponent as ActiveIcon } from '../../../assets/active-policy.svg';
import { POLICY_STATUS } from '../../../context/PolicyContext/types';
import { prettyLimitPrice, prettyFullPrice } from '../../../services/utils';
import { readableHullCoverage } from '../../PurchaseFlow/Coverages/types';
import { SessionContextStore } from '../../../context/SessionContext/SessionContext';
import ViewPolicyButton from '../Buttons/ViewPolicyButton';
import CardButton from '../Buttons/CardButton';
import { useCancellation } from '../../../context/CancellationContext/CancellationContext';
import { ActiveIcon } from '../icons';
import {PolicyContextStore} from '../../../context/PolicyContext/PolicyContext';
import { useMidterm } from '../../../context/MidtermContext/MidtermContext';
    
const PolicyCard = ({ policy, type, ...props }) => {
    const isMobileQuery = useMediaQuery((theme) => theme.breakpoints.down('sm'));
    const { isAdmin } = useContext(SessionContextStore);
    const { startCancellation } = useCancellation();
    const { startMidterm , finishMidterm} = useMidterm();
    const { icon, subtitle } = usePolicyStatus(type);
    const [policyAutoRenew,setPolicyAutoRenew] = useState(policy.auto_renew)
    const { toggleAutoRenew,getPolicies } = useContext(PolicyContextStore);
    return (
            <SkywatchCard
                {...props}
                title={"Your Owners policy"} 
                subtitle={<span>Status: {subtitle}</span>} 
                Icon={icon}
                actionElements={!isMobileQuery && type !== POLICY_STATUS.PENDING &&
                <>
                    {isAdmin && (type === POLICY_STATUS.ACTIVE || type === POLICY_STATUS.SCHEDULED) && <CardButton variant="outlined" className="margin-right-small" onClick={() => startCancellation(policy, type)}>Cancel Policy</CardButton>}
                    {isAdmin && type === POLICY_STATUS.ACTIVE && <CardButton variant="outlined" className="margin-right-small" onClick={() => startMidterm(policy)}>Load Midterm</CardButton>}
                    <ViewPolicyButton url={policy.policy_url}/>
                </> 
                }>
                <Grid container className='margin-top-10 margin-bottom-10'>
                    <Grid xs={12} className="margin-top-6 margin-bottom-6">
                        <Typography variant='body1'>
                        Policy Number: {policy.policy_number}
                        </Typography>
                    </Grid>
                    <Grid xs={12} className="margin-top-6 margin-bottom-6">
                        <Typography variant='body1'>
                        Quote Number: {policy.quote_number}
                        </Typography>
                    </Grid>
                    <Grid xs={12} className="margin-top-6 margin-bottom-6">
                        <Typography variant='body1'>
                        Aircraft Type: {policy.aircraft_type}
                        </Typography>
                    </Grid>
                    <Grid xs={12} className="margin-top-6 margin-bottom-6">
                        <Typography variant='body1'>
                        Aircraft value: {prettyFullPrice(policy.aircraft_value)}
                        </Typography>
                    </Grid>
                    <Grid xs={12} className="margin-top-6 margin-bottom-6">
                        <Typography variant='body1'>
                        Liability Limit: {prettyLimitPrice(policy.liability_limit)}
                        </Typography>
                    </Grid>
                    <Grid xs={12} className="margin-top-6 margin-bottom-6">
                        <Typography variant='body1'>
                        Passenger Limit: {prettyLimitPrice(policy.passenger_limit)}
                        </Typography>
                    </Grid>
                    <Grid xs={12} className="margin-top-6 margin-bottom-6">
                        <Typography variant='body1'>
                        Physical damage coverage: {readableHullCoverage[policy.hull_limit]}
                        </Typography>
                    </Grid>
                    <Grid xs={12} className="margin-top-6 margin-bottom-6">
                        <Typography variant='body1'>
                        Period: {new Date(policy.start_date).toDateString()} - {type === POLICY_STATUS.CANCELED && policy.end_date < policy.start_date ? "CANCELLED" : new Date(policy.end_date).toDateString()}
                        </Typography>
                    </Grid>
                    
                    {isAdmin && type !== POLICY_STATUS.CANCELED && 
                    <Grid container>
                    <Grid item xs={12}> {/* Ensure Divider takes full row */}
        <Divider style={{ width: '100%', margin: '10px 0' }} />
    </Grid>
                        <Grid item xs={6}>
                            {policyAutoRenew && (
                                <Typography variant="body1">
                                
                                    Period Renew At: {new Date(policy.end_date).toDateString()}
                                </Typography>
                            )}
                        </Grid>
                        {isAdmin && <Grid item xs={6} style={{ textAlign: 'right' }}>
                            <Typography variant="body1">
                                <a className="skyblue clickable underline" onClick={ async () => {
                                    await toggleAutoRenew(policy.policy_id) 
                                    setPolicyAutoRenew(!policyAutoRenew)}}>
                                    {policyAutoRenew ? 'Cancel Auto Renew' : 'Turn On Auto Renew'}
                                </a>
                            </Typography>
                        </Grid>}
                    </Grid>
                    }
                    {isMobileQuery && type !== POLICY_STATUS.PENDING && <Grid item container xs={12} direction="row-reverse" className='margin-top-10'>
                        <>
                        <ViewPolicyButton/>
                        {isAdmin && type === POLICY_STATUS.ACTIVE && <CardButton variant="outlined" className="margin-right-small" onClick={() => startCancellation(policy)}>Cancel Policy</CardButton>}
                        {isAdmin && type === POLICY_STATUS.ACTIVE && <CardButton variant="outlined" className="margin-right-small" onClick={() => startMidterm(policy)}>Load Midterm</CardButton>}
                        </>
                    </Grid>}
                </Grid>
            </SkywatchCard>
    );
};

const usePolicyStatus = (type) => {
    const [state, setState] = useState({icon: null, subtitle: null});

    useEffect(()=> {
        switch(type) {
            case POLICY_STATUS.ACTIVE:
                return setState({ subtitle: <span className='green'>Active</span>, icon: <ActiveIcon color="#00c48c"/> })
            case POLICY_STATUS.PENDING:
                return setState({ subtitle: <span className='orange'>Pending</span>, icon: <ActiveIcon color="#ff8203"/> })
            case POLICY_STATUS.SCHEDULED:
                return setState({ subtitle: <span className='orange'>Scheduled</span>, icon: <ActiveIcon color="#ff8203"/> })
            case POLICY_STATUS.CANCELED:
                return setState({ subtitle: <span className='red'>Cancelled</span>, icon: <ActiveIcon color="#eb4242"/> })
        }
    }, [type])

    return state;
}

export default PolicyCard;
