import React, { useContext, useState } from "react";
import { Button, Dialog, Typography, DialogContent, DialogContentText, DialogTitle, DialogActions, useMediaQuery, withStyles } from "@material-ui/core";
import { ReactComponent as AccountSvg } from '../../../assets/sidemenu/login.svg'
import AdminBox from '../AdminBox';
import { FormProvider, useForm } from "react-hook-form";
import { BetterControlledTextField } from "../../Core/Form/ControlledFields";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from 'yup';
import { OrangeButton } from "../../Core/Buttons/OrangeButton";
import { BlueButton } from "../../Core/Buttons/BlueButton";
import { SessionContextStore } from '../../../context/SessionContext/SessionContext';
import FlexView from 'react-flexview/lib';
import EmailAutocomplete from "../SearchAutocomplete/EmailAutocomplete";
import PolicyNumberAutocomplete from "../SearchAutocomplete/PolicyNumberAutocomplete";
import PhoneNumberAutoComplete from "../SearchAutocomplete/PhoneNumberAutoComplete";
import NameAutocomplete from "../SearchAutocomplete/NameAutocomplete";
import DividerWithText from '../../Core/DividerWithText'

const CustomDialog = withStyles(theme => ({
    paper: {
        width: '100%',
        padding: '10px'
    }
}))(Dialog);

export const QuoteUserSchema = yup
.object()
  .shape({
    email: yup.string().required("Required").trim().email("Must be a valid email address")
})
.required();

const FindExistingUserPopup = ({open, onCancel,onSubmit}) => {
    const { register, errors, getValues, triggerValidation, control } = useForm()
    const { impersonateUser } = useContext(SessionContextStore);
    const handleClose = () => {
        onCancel && onCancel()
    }
    const selectUser = async (user) => {
        if(!!user){
          handleClose()
          onSubmit && onSubmit(user)
        }
    }

    return(
        <CustomDialog maxWidth={'sm'} open={open} onClose={handleClose}>
                <DialogTitle style={{ cursor: 'move' }} id="draggable-dialog-title">
                  <Typography variant="h3" className="blue bold">Search existing user</Typography>
                </DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        <FlexView column>
                            <div className="text-body black margin-top-small textcenter">Search by</div>
                            <div className="text-body black margin-top-basic margin-bottom-basic">Insert the insured's email</div>
                            <EmailAutocomplete selectUser = {selectUser}/>
                            <DividerWithText>Or</DividerWithText>
                            <div className="text-body black margin-top-basic margin-bottom-basic">Insured's policy number</div>
                            <PolicyNumberAutocomplete selectUser = {selectUser}/>
                            <DividerWithText>Or</DividerWithText>
                            <div className="text-body black margin-top-basic margin-bottom-basic">Insured's name</div>
                            <NameAutocomplete selectUser = {selectUser}/>
                            <DividerWithText>Or</DividerWithText>
                            <div className="text-body black margin-top-basic margin-bottom-basic">Insured's phone number</div>
                            <PhoneNumberAutoComplete selectUser ={selectUser}/>
                            <div onClick={handleClose} className="margin-bottom-basic text-body textcenter gray underline clickable">Close</div>
                        </FlexView>
                    </DialogContentText>
                </DialogContent>
        </CustomDialog>)
}

export default FindExistingUserPopup;