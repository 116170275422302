import { TextField } from "@material-ui/core";
import { Autocomplete } from "@material-ui/lab";
import React, { useCallback, useContext, useEffect, useState } from "react";
import { useFormContext } from "react-hook-form";
import { debounce } from "../../../services/utils";
import brokerApi from "../../../api/broker.api";

const EmailAutocomplete = ({...props}) => {
    const [emails, setEmails] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const { selectUser } = props

    async function filter(value) {
        if(value === ""){
            setEmails([]);
            return;
        } 
        setIsLoading(true);
        const { data } = await brokerApi.searchUserByEmail(value)
        setEmails([...data]);
        setIsLoading(false);
    }
    
    const debounceHandler = useCallback(debounce(filter, 300), []);

    const handleFiltering = (e, newval) => {
        if(newval.length >= 3){
            debounceHandler(newval);
        }
    }

    const handleEmailPick = async (e, value) => {
        selectUser(value)
    }

    return (
        <Autocomplete
            fullwidth
            getOptionLabel={(option) => `${option.email}`}
            loading={isLoading}
            className="margin-top-8"
            onChange={handleEmailPick}
            onInputChange={handleFiltering}
            options={emails}
            filterOptions={(x) => x}
            renderInput={(params) => (
                <TextField
                    {...params}
                    fullWidth={true}
                    variant="outlined"
                    className="margin-top-8 margin-bottom-basic"
                    placeholder="Email"
                />
            )}
        />
    );
}

export default EmailAutocomplete;